import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './App.css';
import pfp from './assets/profile_pic.png';
import languages from './assets/logocloud.png';
import video from './assets/banner.mp4';
import codepic from './assets/projectOne.png'
import sky from './assets/sky.jpg'

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xyyrwrkl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className='contact-form'>
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control"/>
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-control"/>
      </div>
      <div className="form-group">
        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} className="form-control"></textarea>
      </div>
      <button type="submit" className='btn btn-primary' >Send Message</button>
    </form>
  );
}

function App() {
  const [showNavbar, setShowNavbar] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop;
      const sections = document.querySelectorAll('.section');
  
      let currentSectionId = '';
      let maxSectionTop = 0;
  
      sections.forEach(section => {
        const sectionTop = section.offsetTop + 300;
        if (distanceY >= sectionTop && sectionTop > maxSectionTop) {
          maxSectionTop = sectionTop;
          currentSectionId = section.id;
        }
      });
  
      setActiveSection(currentSectionId);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  
  return (
    <div className="App">
      <section id="portfolio" className="section" style={{backgroundImage: `url(${sky})`}}>
        <header>
          <div className='head-container'>
            <span><h1>Hello, I Am Davis.</h1></span>
            <span><h1>I'm a CSE Student at Ohio State.</h1></span>
            <button><Link className={`nav-link ${activeSection === 'about' ? 'active' : ''}`} to="about" smooth={true} duration={500}>About &darr;</Link></button>
          </div>
        </header>
      </section>

      <div className='whole-container' style={{backgroundImage: `url(${sky})`}}>
      <nav className={`navbar show`}>
        <ul>
          <li><Link className={`nav-link ${activeSection === 'about' ? 'active' : ''}`} to="about" smooth={true} duration={500}>About</Link></li>
          <li><Link className={`nav-link ${activeSection === 'projects' ? 'active' : ''}`} to="projects" smooth={true} duration={500}>Projects</Link></li>
          <li><Link className={`nav-link ${activeSection === 'contact' ? 'active' : ''}`} to="contact" smooth={true} duration={500}>Contact</Link></li>
        </ul>
      </nav>
        <section id="about" className="section">
          <span><h1>About</h1></span>
            <div className='about-container'>
              <div className="about-left">
                <img src={pfp} alt="Your Picture" className="about-image" />
                <p>Hello, I am a driven sophomore at OSU with a fervor for coding and technology. Proficient in Python, JavaScript, and Java, I specialize in data science and machine learning. Currently, I'm engaged in mastering SkyKit and Skylearn, utilizing advanced tools like Pandas dataframes to extract valuable insights. Eager to contribute to cutting-edge projects and drive innovation in the tech sphere.</p>
              </div>
              <div className="about-right">
                <div className="about-languages">
                  <img src={languages} alt="Language 1" />
                </div>
              </div>
            </div>
        </section>
        <section id="projects" className="section">
          <span><h1>Projects</h1></span>
          <div className='project-container'>
            <div className='image-text'>
              <h1>Sports Analysis</h1>
              <h3>Analyzing sports stats using SkyKit and Skylearn: coming soon...</h3>
            </div>
            <div className='project-image'>
                <div className='image-background'>
                <img src={codepic} alt='picofcode'/>
                </div>
            </div>
          </div>
        </section>
        <section id="contact" className="section">
          <div className='contact-header'>
            <span className='head'><h1>Contact</h1></span>
            <span><h3>Get in touch with me</h3></span>
            <ContactForm />
          </div>
          <div className='footer-container'>
            <div className='footer'>
              <a href='https://www.linkedin.com/in/davis-helman/'><LinkedInIcon className='icon'/></a>
              <span><p>&copy; Davis Helman</p></span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
